export default Done => {
    return (
        <main className="text-center">
            <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                <h1>Thank You</h1>
                <br />
                    <p>We will review the information you submitted and connect loans to your account within 24 hours. You can <a href={"/app/loans"}>check the status here</a>.</p>
                </div>
            </div>
        </main>
    )
}
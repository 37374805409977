import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";

export default function StartRecovery() {

  const [resetResetCodeRequest, setResetCodeRequest] = useState({});

  const handleChange = e =>{
    setResetCodeRequest({...resetResetCodeRequest, [e.target.name]: e.target.value});
  }

  const sendCode = async () => {
    let body = {...resetResetCodeRequest};
    const response = await Http.request({uri: '/auth/recovery/send-code', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      window.location.assign(`/auth/recover/complete`);
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full text-poppins">
        <div className="w-full py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Password Recovery</h2>
              <p className="mt-2 text-sm">
              Please enter your email address below. We will send a code to be used to reset your password.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input 
                        className='bg-gray-300 w-full px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                        id="email" 
                        name="email" 
                        type="email"
                        value={resetResetCodeRequest.email} 
                        required={true} 
                        autoComplete="email"
                        onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-rfs-green-009933 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => sendCode()}
                    >
                      Send Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

}
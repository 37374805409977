export default ConfirmContact => {
    return (
        <main className="text-center">
            <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                <h1>Please confirm contact details.</h1>
                <br />
                <p>To further protect accounts for yourself and other customers, we require one final confirmation step, since the email address you registered with was not listed on the associated profile. Please confirm these details to connect the loan(s) to your profile.</p>
                </div>
            </div>
        </main>
    )
}
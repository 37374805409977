import { render } from "react-dom";
import App from "./App";
import { createRoot } from 'react-dom/client';

window.setConfig = async () => {
  let configHost = process.env.REACT_APP_API_CONFIG_HOST;
  if ( window.location.host.indexOf('app.myrfsloan.com') > -1 ) {
    configHost = `${configHost}?env=prod`;
  } else if ( window.location.host.indexOf('local') > -1 ) {
    configHost = `${configHost}?env=local`;
  }
  const response = await fetch(configHost).then(response => response.json());
  localStorage.setItem('HOST_CONFIG', JSON.stringify(response));
};

window.getConfig = key => {
  let configData = {};
  let configString = localStorage.getItem('HOST_CONFIG');
  if ( configString ) configData = JSON.parse(configString);
  configData = {...configData, ...process.env};
  return configData[key] || configData[`REACT_APP_API_${key}`] || configData[key.replace('REACT_APP_API_', '')] || null;
};

window.friendlyCase = function(string) {
  let result = string.replace( /([A-Z])/g, " $1" );
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  let knownWords = {
    "Trn": 'TRN',
    "Date Of Birth": 'Date of Birth',
  }
  for ( let w in knownWords ) {
    if ( finalResult === w ) {
      return knownWords[w];
    }
  }
  return finalResult;
};

String.prototype.toFriendlyCase = function() {
  return window.friendlyCase(this);
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App />
);
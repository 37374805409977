import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Loan from "./pages/Loans";
import Profile from "./pages/Profile";
import Connect from "./pages/Connect";
import LoginV2 from "../Auth/LoginV2";

export default function Main() {
    return (
      <main>
          {(!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'invalid_client') &&
              <>
                  <Routes>
                      <Route path="*" element={<LoginV2 />}/>
                  </Routes>
              </>
          }

          {localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client' &&
              <Routes>
                  <Route path="dashboard" element={<Dashboard/>}/>
                  <Route path="loans/*" element={<Loan/>}/>
                  <Route path="profile/*" element={<Profile/>}/>
                  <Route path="connect/*" element={<Connect/>}/>
                  <Route path="*" element={<Dashboard/>}/>
              </Routes>
          }
      </main>
    );
  }
export default NotConnectedYet => {
    return (
        <>
            <div className="text-center">
                <br /><br />
                <h1 className="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">Your profile is <strong>not</strong> connected yet</h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">You can use your personal information to connect your profile to your account at Regions Financial, so that you can see your loan details here.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a href="/app/connect"
                       className="rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Connect My Profile</a>
                </div>
            </div>
        </>
    )
}
import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";

export default function StartRegistration() {

  const [newAccount, setNewAccount] = useState({});

  const handleChange = e =>{
    setNewAccount({...newAccount, [e.target.name]: e.target.value});
  }

  const submitRegistration = async () => {
    let body = {...newAccount};
    const response = await Http.request({uri: '/auth/register', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.setItem('REGISTER_PARAMS_EMAIL', newAccount.email);
      let sendCodeResponse = await Http.request({uri: '/auth/register/send-code', body: {email: body.email}});
      if (sendCodeResponse.notification) {
        Notifier(sendCodeResponse.notification);
      }
      if ( sendCodeResponse.result === 'success' ) {
        window.location.replace(`/auth/register/confirm`);
      }
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full text-poppins mt-0">
        <div className="flex w-full mt-0">
          <div className="w-2/5">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Register</h2>
              <p className="mt-2 text-sm text-gray-600">
              Please register your account below. You'll then be taken through a self verification wizard, after which you will be able to retrieve details of loans if you have any with us.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input 
                        id="email" 
                        name="email" 
                        type="email"
                        value={newAccount.email} 
                        required={true} 
                        autoComplete="email"
                        onChange={e => handleChange(e)}
                        className='bg-gray-300 w-2/3 px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-900">
                      Phone Number
                    </label>
                    <div className="mt-1">
                      <input 
                        id="phoneNumber" 
                        name="phoneNumber" 
                        type="text" 
                        value={newAccount.phoneNumber}
                        autoComplete="phoneNumber"
                        onChange={e => handleChange(e)}
                        className='bg-gray-300 w-2/3 px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                      Password
                    </label>
                    <div className="mt-1">
                      <input 
                        className='bg-gray-300 w-2/3 px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                        id="password" 
                        name="password" 
                        type="password"
                        value={newAccount.password}
                        onChange={e => handleChange(e)} 
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-900">
                      Password (repeat to confirm)
                    </label>
                    <div className="mt-1">
                      <input 
                        className='bg-gray-300 w-2/3 px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                        id="passwordConfirm" 
                        name="passwordConfirm" 
                        value={newAccount.passwordConfirm}
                        type="password"
                        onChange={e => handleChange(e)} 
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex justify-center rounded-md border border-transparent bg-rfs-green-009933 py-2 px-20 text-sm font-medium text-white shadow-sm focus:outline-none "
                      onClick={() => submitRegistration()}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-3/5 pl-20 pt-20">
            <img src="/assets/images/smartphone.png" />
          </div>
        </div>
      </div>
    </Fragment>
  );

}
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import Recover from "./Recover";
import Logout from "./Logout";

export default function Auth() {
    return (
      <>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          <div className="h-96 rounded-lg p-10 border-gray-200">
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="register/*" element={<Register />} />
            <Route path="recover/*" element={<Recover />} />
          </Routes>
          </div>
        </div>
        </div>
      </main>
      </>
    );
  }
import { Fragment, useState } from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";

export default function Login() {

  const [credentials, setCredentials] = useState({});

  const handleChange = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }

  const submitLogin = async () => {
    let body = { ...credentials };
    const response = await Http.request({ uri: '/auth/login', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      localStorage.setItem('access_token', response.body.access_token);
      localStorage.removeItem('is_registered');
      if (response.body.is_registered) localStorage.setItem('is_registered', "yes");
      if (window.location.href.indexOf('/auth') < 0) {
        window.location.reload();
      } else {
        window.location.replace(`/`);
      }
    }
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      submitLogin();
    }
  }

  return (
    <Fragment>
      <div className="flex flex-col sm:flex-row min-h-full text-poppins mt-0">
        <div className="flex flex-1 flex-col px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24 mt-0">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="font-bold tracking-tight text-gray-900" style={{ fontSize: '3em' }}>Log In</h2>
              <p className="mt-5 text-sm text-gray-600">
                Please log in with your credentials below.
              </p>
            </div>

            <div className="mt-10">
              <div className="mt-10">
                <div action="#" method="POST" className="space-y-6" onKeyPress={e => handleEnterKey(e)}>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={credentials.email}
                        autoComplete="email"
                        onChange={e => handleChange(e)}
                        className='bg-gray-300 w-full px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                      />
                    </div>
                  </div>

                  <div className="space-y-1 mb-10">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={credentials.password}
                        onChange={e => handleChange(e)}
                        className='bg-gray-300 w-full px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-10">
                    <div className="text-sm">
                      <a href="/auth/recover" className="font-medium text-link hover:text-rfs-green-009933">
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div className="mt-10">
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-rfs-green-009933 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none "
                      onClick={() => submitLogin()}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col px-4 sm:px-6 lg:flex-col lg:px-10 xl:px-24 text-center">
          <img src="/assets/images/phone.png" alt="phone" className="w-2/3 mx-auto" />
          <ul className={"plain-list mt-5"}>
            <li>No account yet?{' '}<a href="/auth/register" className="font-medium">Register here</a></li>
            <li>Forgot password?{' '}<a href="/auth/recover" className="font-medium">Reset it here</a></li>
          </ul>
        </div>
      </div>
      <br /><br />
      <hr />
      <div className="md:w-3/4 text-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="mt-20">
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900" style={{ fontSize: "3em" }}>Need Help?</h2>
            <ul className="mt-10">
              <li className="mt-10">If you attempted to sign up and received a code, or you changed your email address,{' '}<a href="/auth/register/confirm" className="font-medium">confirm your email address here</a>.</li>
              <li className="mt-10">If you attempted to sign up or change an email and your confirmation code expired,{' '}<a href="/auth/register/continue" className="font-medium">request a new one here</a>.</li>
            </ul>
          </div>
          <div className="p-10">
            <img src="/assets/images/info-icon.png" className="w-full" />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
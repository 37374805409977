import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

import StartRegistration from "./pages/StartRegistration";
import ConfirmEmail from "./pages/ConfirmEmail";
import ContinueRegistration from "./pages/ContinueRegistration";

  export default function Register() {
      return (
        <Routes>
          <Route path="/" element={<StartRegistration />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
          <Route path="/continue" element={<ContinueRegistration />} />
        </Routes>
      );
    }
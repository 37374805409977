import { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import Http from "../../../Utils/Http";
import Notifier from "../../../Utils/Notifier";
import {CurrencyText, RelativeDate} from "../../../Utils/Template";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import { MdStar } from "react-icons/md";
import NotConnectedYet from "./Connect/components/NotConnectedYet";

export default function Dashboard() {

  const [user, setUser] = useState({});
  const [loanSummaryStats, setLoanSummaryStats] = useState([]);
  const [profileSummaryStats, setProfileSummaryStats] = useState([]);
  const [isRegistered, setIsRegistered] = useState(true);

  useEffect(() => {
    fetchDashboardSummary();
  }, []);

  const fetchDashboardSummary = async () => {
    let body = {};
    const response = await Http.request({uri: `/dashboard/get-summary`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      setLoanSummaryStats(response.body.loanSummaryStats);
      setProfileSummaryStats(response.body.profileSummaryStats);
      setIsRegistered(response.body.isRegistered);
      setUser(response.body.user);
    }
  }

  const transformStat = (name, value) => {
    if ( name === 'Last Login' ) return <RelativeDate value={value} />;
    if ( ['Total Balance', 'Total Arrears', 'Pending Approval'].includes(name) ) return <CurrencyText value={value} />;
    return value;
  };

    return (
      <>
        <Header title="Dashboard" />

        {!isRegistered && false && <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 p-10 bg-grey">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">You have no loans connected to your profile on the Customer Portal. If you've applied for a loan at Regions, you can use your personal details to <a href={"/app/connect"}><strong>connect the loan here</strong></a>.</p>
              </div>
            </div>
          </div>
        </div>}

        {!localStorage.is_registered && <>

          <NotConnectedYet />

          <br/><br/>

        </>
        }

        {localStorage.is_registered && <>

          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="bg-white p-6">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="flex-shrink-0">
                    <img className="mx-auto h-20 w-20 rounded-full" src={user.imageUrl} alt=""/>
                  </div>
                  <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p className="text-sm font-medium text-gray-600">Welcome,</p>
                    <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user?.name?.replace('NotSpecified', '').split(' ')[0]}<br/><span
                        className={`radius-20 customer-classification customer-classification-` + (user?.classification || 'elite').trim().toLowerCase().replace(' ', '-')}>{user.classification || 'Elite'}<sup>*</sup></span>
                    </p>
                    <p className="">

                    </p>
                  </div>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0">
                  <a
                      href="/app/profile"
                      className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    Edit Profile
                  </a>
                </div>
              </div>
            </div>

            <div className="px-10 py-10 sm:px-0">
              <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 p-10">
                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {profileSummaryStats.map((stat) => (
                      <div key={stat.label}
                           className={`bg-${stat.backgroundColour || 'white'} text-white overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6`}>
                        <dt className="truncate text-sm font-medium">{stat.label}</dt>
                        <dd className="mt-1 text-3xl tracking-tight">{transformStat(stat.label, stat.value)}</dd>
                      </div>
                  ))}
                </dl>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {loanSummaryStats.map((item) => (
                      <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-800">{item.name}</dt>
                        <dd className="mt-1 text-3xl tracking-tight text-gray-900">{transformStat(item.name, item.stat)}</dd>
                      </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </>
        }
      </>
    );
  }
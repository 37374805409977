import {Fragment, useEffect, useState} from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";

export default function Logout() {

  useEffect(() => {
    submitLogout();
  });

  const submitLogout = async () => {
    const response = await Http.request({uri: '/auth/logout'});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.removeItem('access_token');
      localStorage.clear();
      localStorage.setItem('access_token', 'invalid_client');
      window.setConfig();
      window.location.replace(`/`);
    }
  }

    return (
      <Fragment>
        If you're not redirected automatically, <a href={"/"}>click here</a>.
      </Fragment>
    );
  }
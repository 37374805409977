import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Template, { Input } from "../../../../../Utils/Template";
import Waiting from "../components/Waiting";
import NoResult from "../components/NoResult";
import ConfirmContact from "../components/ConfirmContact";
import Done from "../components/Done";

export default function ConnectUsingBio() {

  const [personalInfo, setPersonalInfo] = useState({});
  const [page, setPage] = useState('START');

  const handleChange = e =>{
    setPersonalInfo({...personalInfo, [e.target.name]: e.target.value});
  }

  const submitPersonalInfo = async () => {
    setPage('WAITING');
    let body = {...personalInfo};
    const response = await Http.request({uri: '/connect/bio/validate', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result !== 'success' ) {
      // window.location.replace('/app/loans');
      setPage('NO_RESULT');
    } else {
      if( response.body.status === 'success' ) localStorage.setItem('is_registered', 'true');
      if( response.body.status === 'failure' ) setPage('NO_RESULT');
      else setPage('DONE');
    }
  }


    return (
      <>
        <Header title="Connect My Loan Accounts" />
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 p-10">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">You're connecting your loan account using personal information. Please note, you will only be allowed to try this once, for security reasons.{' '}<a href="/app/connect">Try another way</a></p>
              </div>
            </div>
          </div>
          
          {!page || page === 'START' && 
          <main>
            <div className="mt-8">

              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <p className={'text-lg'}>
                    Please enter your personal details to continue.
                  </p>
                  <p className={'text-red-600'}><>Except "Middle Name", <strong>ALL FIELDS</strong> on this screen are required. Failure to complete these fields will result in a failed attempt to connect your profile.</>
                  </p>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <div>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="firstName" 
                            name="firstName" 
                            type="text"
                            value={personalInfo.firstName} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <label htmlFor="middleName" className="block text-sm font-medium text-gray-700">
                          Middle Name
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="middleName" 
                            name="middleName" 
                            type="text"
                            value={personalInfo.middleName} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="lastName" 
                            name="lastName" 
                            type="text"
                            value={personalInfo.lastName} 
                            required={true}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="trn" className="block text-sm font-medium text-gray-700">
                        TRN
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="trn" 
                          name="trn" 
                          type="text"
                          value={personalInfo.trn} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <br />
                      <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">
                        Date of Birth
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="dateOfBirth" 
                          name="dateOfBirth" 
                          type="date"
                          value={personalInfo.dateOfBirth} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div>
                    <button
                      type="submit"
                      className="flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => (personalInfo.firstName &&
                        personalInfo.lastName &&
                        personalInfo.trn &&
                        personalInfo.dateOfBirth) ? setPage('CONTACT_DETAILS') : Notifier({type: 'error', text: 'Please complete all required fields.'})}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </main>
          }

          {!page || page === 'CONTACT_DETAILS' && 
          <main>
            <div className="mt-8">

              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <p>
                    Please enter at least one phone number and at least one email address you have used in the past that would likely be recorded in our database and associated with your loan. The more you enter, the better.
                  </p>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <div>
                        <label htmlFor="phoneNumber1" className="block text-sm font-medium text-gray-700">
                          1. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber1" 
                            name="phoneNumber1" 
                            type="text"
                            value={personalInfo.phoneNumber1} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                      <label htmlFor="phoneNumber2" className="block text-sm font-medium text-gray-700">
                          2. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber2" 
                            name="phoneNumber2" 
                            type="text"
                            value={personalInfo.phoneNumber2} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                      <label htmlFor="phoneNumber3" className="block text-sm font-medium text-gray-700">
                          3. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber3" 
                            name="phoneNumber3" 
                            type="text"
                            value={personalInfo.phoneNumber3} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email1" className="block text-sm font-medium text-gray-700">
                        1. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email1" 
                          name="email1" 
                          type="text"
                          value={personalInfo.email1} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <br />
                      <label htmlFor="email2" className="block text-sm font-medium text-gray-700">
                        2. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email2" 
                          name="email2" 
                          type="text"
                          value={personalInfo.email2} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <br />
                      <label htmlFor="email3" className="block text-sm font-medium text-gray-700">
                        3. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email3" 
                          name="email3" 
                          type="text"
                          value={personalInfo.email3} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div>
                  <button
                      type="submit"
                      className="justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setPage('START')}
                    >
                      Back
                    </button>
                    &nbsp;
                    &nbsp;
                    <button
                      type="submit"
                      className="justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => submitPersonalInfo()}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </main>
          }

          {page === 'WAITING' && 
            <Waiting />
          }

          {page === 'NO_RESULT' && 
            <NoResult />
          }

          {page === 'CONFIRM_CONTACT' &&
            <ConfirmContact />
          }

          {page === 'DONE' &&
              <Done />
          }

          <br /><br />
          
        </div>
      </>
    );
  }
import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";

export default function ConfirmEmail() {

  const [confirmRequest, setConfirmRequest] = useState({email: localStorage.getItem('REGISTER_PARAMS_EMAIL')});

  const handleChange = e =>{
    setConfirmRequest({...confirmRequest, [e.target.name]: e.target.value});
  }

  const submitConfirmRequest = async () => {
    let body = {...confirmRequest};
    const response = await Http.request({uri: '/auth/register/confirm', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.removeItem('REGISTER_PARAMS_EMAIL');
      localStorage.removeItem('LOGIN_PARAMS_EMAIL', confirmRequest.email);
      window.location.replace(`/auth/login`);
    }
  }

  const resendCode = async () => {
    let body = {email: confirmRequest.email};
    const response = await Http.request({uri: '/auth/register/send-code', body});
    if ( response.body ) {
      if (response.body.notification) {
        Notifier(response.body.notification);
      }
    } else {
      Notifier(response.notification);
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full text-poppins">
        <div className="w-full py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Confirmation</h2>
              <p className="mt-2 text-sm">
              We sent a code to the email address you provided. Please enter the code below.
              </p>
              <br />
              <p className="mt-2 text-sm">
              NB: For your online safety, we did not send you a link, and we will never send you a link by email or SMS.
              </p>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input 
                        className='bg-gray-300 w-full px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                        id="email" 
                        name="email" 
                        type="email"
                        value={confirmRequest.email} 
                        required={true} 
                        autoComplete="email"
                        onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="confirmationCode" className="block text-sm font-medium text-gray-700">
                      Confirmation Code
                    </label>
                    <p className="font-small text-gray-600 text-sm">
                      <small>Please enter the code you received by email exactly as it appears.</small>
                    </p>
                    <div className="mt-1">
                      <input 
                        className='bg-gray-300 w-full px-5 py-2 mt-5 focus:bg-gray-400 focus:outline-none active:outline-none'
                        id="confirmationCode" 
                        name="confirmationCode" 
                        value={confirmRequest.confirmationCode} 
                        type="text" 
                        autoComplete="confirmationCode"
                        onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-rfs-green-009933 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => submitConfirmRequest()}
                    >
                      Confirm Email
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent border-rfs-green-009933 py-2 px-4 text-sm font-medium text-rfs-green-009933 shadow-sm bg-white"
                      onClick={() => resendCode()}
                    >
                      Resend Code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

}
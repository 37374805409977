import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import NotConnectedYet from "../../Connect/components/NotConnectedYet";

export default function Browse() {

  const [loans, setLoans] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchLoans();
  }, []);

  const fetchLoans = async () => {
    let body = {};
    const response = await Http.request({uri: `/loans/all`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      setLoans(response.body);
      setLoaded(true);
    }
  }

    return (
      <>
        <Header title="My Loans" />
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 p-10">
          {localStorage.is_registered && <>
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">This page shows all the loans you have connected to your profile.</p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <a href="/app/connect" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                    More Info
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <br />


          {loans.length > 0 && <div className="">
              <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {loans.map((loan) => (
                    <li key={loan.loanNumber}
                        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">#{loan.loanNumber}</h3>
                            <span
                                className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                          {loan.status.toFriendlyCase()}
                        </span>
                          </div>
                          <p className="mt-1 truncate text-sm text-gray-500">{loan.loanProductName}</p>
                        </div>
                        <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={loan.imageUrl} alt=""/>
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="flex w-0 flex-1">

                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <a
                                href={`/app/loans/${loan.loanNumber}`}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                            >
                              <ArrowRightCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                              <span className="ml-3">View Loan</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                ))}
              </ul>
            </div>}

            {!loans.length && loaded && <>

              <div className="rounded-md bg-blue-50 p-25 bg-white" style={{padding: "25px"}}>

                <div className="text-center">
                  <h1 className="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">No Loans Yet</h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600">We currently do not have any loans on record for you.</p>
                </div>

              </div>

            </>}

          </>
          }

          {!localStorage.is_registered && <>

            <NotConnectedYet />

            <br/><br/>

          </>
          }

        </div>
      </>
    );
  }
export default NoResult => {
    return (
        <main className="text-center">
            <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                <h1>Sorry, we found nothing.</h1>
                <br />
                    <p>To get access to your loans, please contact us and verify your details. Call us at <a href={"tel:+1876-926-2100"}>876-926-2100</a>.</p>
                </div>
            </div>
        </main>
    )
}
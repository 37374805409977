import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Browse from "./pages/Browse";
import Loan from "./pages/Loan";

export default function Main() {
    return (
      <main>
        <Routes>
          <Route path="/" element={<Browse />} />
          <Route path="/:loanNumber" element={<Loan />} />
        </Routes>
      </main>
    );
  }
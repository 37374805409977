import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Template, { Input } from "../../../../../Utils/Template";
import Waiting from "../components/Waiting";
import NoResult from "../components/NoResult";
import ConfirmContact from "../components/ConfirmContact";
import Done from "../components/Done";

export default function ConnectUsingAccounts() {

  const [accountInfo, setAccountInfo] = useState({});
  const [page, setPage] = useState('START');

  const handleChange = e =>{
    setAccountInfo({...accountInfo, [e.target.name]: e.target.value});
  }

  const submitAccountInfo = async () => {
    setPage('WAITING');
    let body = {...accountInfo};
    const response = await Http.request({uri: '/connect/accounts/validate', body});
    if (response.notification) {
      Notifier(response.notification);
    }console.log(response)
    if ( response.body.status !== 'success' && response.result !== 'success' ) {
      // window.location.replace('/app/loans');
      setPage('NO_RESULT');
    } else {
      if( response.body.status === 'success' ) localStorage.setItem('is_registered', 'true');
      setPage('DONE')
    }
  }


    return (
      <>
        <Header title="Connect My Loan Accounts" />
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 p-10">
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">You're connecting your loan account using loan account information. Please note, you will only be allowed to try this once, for security reasons.{' '}<a href="/app/connect">Try another way</a></p>
              </div>
            </div>
          </div>
          
          {!page || page === 'START' && 
          <main>
            <div className="mt-8">

              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <p>
                  Please enter account details to continue. If you don't have these details, you may contact us to retrieve them. If you've received a loan statement, you will find the details there.
                  </p>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <div>
                        <label htmlFor="clientNumber" className="block text-sm font-medium text-gray-700">
                          Client Account Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="clientNumber" 
                            name="clientNumber" 
                            type="text"
                            value={accountInfo.clientNumber} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <label htmlFor="loanNumber" className="block text-sm font-medium text-gray-700">
                          Loan Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="loanNumber" 
                            name="loanNumber" 
                            type="text"
                            required={true}
                            value={accountInfo.loanNumber} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">
                          Amount Borrowed
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="loanAmount" 
                            name="loanAmount" 
                            type="text"
                            value={accountInfo.loanAmount} 
                            required={true}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div>
                    <button
                      type="submit"
                      className="flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => submitAccountInfo()}
                    >
                      Validate
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </main>
          }

          {!page || page === 'CONTACT_DETAILS' && 
          <main>
            <div className="mt-8">

              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <p>
                    Please enter at least one phone number and at least one email address you have used in the past that would likely be recorded in our database and associated with your loan. The more you enter, the better.
                  </p>
                  <br />
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <div>
                        <label htmlFor="phoneNumber1" className="block text-sm font-medium text-gray-700">
                          1. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber1" 
                            name="phoneNumber1" 
                            type="text"
                            value={accountInfo.phoneNumber1} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                      <label htmlFor="phoneNumber2" className="block text-sm font-medium text-gray-700">
                          2. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber2" 
                            name="phoneNumber2" 
                            type="text"
                            value={accountInfo.phoneNumber2} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                      <label htmlFor="phoneNumber3" className="block text-sm font-medium text-gray-700">
                          3. Phone Number
                        </label>
                        <div className="mt-1">
                          <Input 
                            id="phoneNumber3" 
                            name="phoneNumber3" 
                            type="text"
                            value={accountInfo.phoneNumber3} 
                            required={true} 
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email1" className="block text-sm font-medium text-gray-700">
                        1. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email1" 
                          name="email1" 
                          type="text"
                          value={accountInfo.email1} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <br />
                      <label htmlFor="email2" className="block text-sm font-medium text-gray-700">
                        2. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email2" 
                          name="email2" 
                          type="text"
                          value={accountInfo.email2} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <br />
                      <label htmlFor="email3" className="block text-sm font-medium text-gray-700">
                        3. Email Address
                      </label>
                      <div className="mt-1">
                        <Input 
                          id="email3" 
                          name="email3" 
                          type="text"
                          value={accountInfo.email3} 
                          required={true} 
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div>
                  <button
                      type="submit"
                      className="justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setPage('START')}
                    >
                      Back
                    </button>
                    &nbsp;
                    &nbsp;
                    <button
                      type="submit"
                      className="justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => submitAccountInfo()}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </main>
          }

          {page === 'WAITING' && 
            <Waiting />
          }

          {page === 'NO_RESULT' && 
            <NoResult />
          }

          {page === 'CONFIRM_CONTACT' &&
              <ConfirmContact />
          }

          {page === 'DONE' &&
              <Done />
          }

          <br /><br />
          
        </div>
      </>
    );
  }
export default Waiting => {
    return (
        <main className="text-center">
            <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                <h1>Thank You</h1>
                <br />
                <p>Please wait a moment while we search for any close enough matches to the details you entered.</p>
                </div>
            </div>
        </main>
    )
}
import axios from "axios"

const handleError = error => {
    const defaultError= {error: true, notification: {text: 'Sorry, we could not complete your request right now. Please try again later.', type: 'error'}};
    try {
        let status = error.response.status;
        if ( [422, 403, 400, 404].includes(status) ) {
            let errorNotification = error.response.data.notification;
            if ( !errorNotification ) errorNotification = defaultError.notification;
            let notification = {...errorNotification, type: 'error'};
            return {notification};
        } else if ( [401].includes(status) ) {
            localStorage.removeItem('access_token')
            window.location.assign('/auth/login');
            return;
        }

    } catch (e) {
        return defaultError;
    }
    return defaultError;
}

const request = async args => {
    let method = ( args.method || 'POST' ).toUpperCase();
    let {uri, url, body, service} = args;
    let headers = {access_token: localStorage.getItem('access_token')};
    let config = {headers};

    if ( !url ) {
        let serviceEndpointHost = window.getConfig(`${(service || 'CUSTOMER_PORTAL').toUpperCase()}_HOST`);
        url = `${serviceEndpointHost}${uri}`;
    }

    if ( method === 'GET' ) {
        await axios.get(url, config).then(response => {
            return {result: 'success', body: response.data};
        })
        .catch(error => {return handleError(error);});
    } else {
        return await axios.post(url, body, config).then(response => {
            return {result: 'success', body: response.data};
        })
        .catch(error => {return handleError(error);});
    }
}

const Http = {request};

export default Http;
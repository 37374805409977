import { Fragment, useState } from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";

export default function LoginV2() {

  const [credentials, setCredentials] = useState({});

  const handleChangeLogin = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }

  const submitLogin = async () => {
    let body = { ...credentials };
    const response = await Http.request({ uri: '/auth/login', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      localStorage.setItem('access_token', response.body.access_token);
      if (window.location.href.indexOf('/auth') < 0) {
        window.location.reload();
      } else {
        window.location.replace(`/`);
      }
    }
  }

  const [newAccount, setNewAccount] = useState({});

  const handleChange = e => {
    setNewAccount({ ...newAccount, [e.target.name]: e.target.value });
  }

  const submitRegistration = async () => {
    let body = { ...newAccount };
    const response = await Http.request({ uri: '/auth/register', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      localStorage.setItem('REGISTER_PARAMS_EMAIL', newAccount.email);
      let sendCodeResponse = await Http.request({ uri: '/auth/register/send-code', body: { email: body.email } });
      if (sendCodeResponse.notification) {
        Notifier(sendCodeResponse.notification);
      }
      if (sendCodeResponse.result === 'success') {
        window.location.replace(`/auth/register/confirm`);
      }
    }
  }

  const handleEnterKey = (e, target) => {
    if (e.key === 'Enter') {
      if (target === 'register') submitRegistration();
      if (target === 'login') submitLogin();
    }
  }

  return (
    <Fragment>

      <div className="grid min-h-full grid-cols-1 md:grid-cols-2 gap-0 bg-landing-page-top">

        <div className=" p-0">
          <img src={'/assets/images/tablet.png'} className="w-full" />
        </div>

        <div class=" pt-10 text-poppins md:ml-20">
          <div class="font-bold text-poppins mt-10" style={{fontSize: '2.5em', lineHeight: '0.5'}}>Get started with</div>
          <div class="font-extrabold text-poppins text-rfs-green-009933" style={{fontSize: '3em'}}>MYRFSLoan!</div>
          <div class="font-bold text-xl text-poppins">GET POINTS FOR PAYMENTS</div>
          <div class="font-semibold text-md text-base text-poppins mt-2">Get points every time you make a payment</div>
          <div className="">
            <ul class="list-disc list-inside text-medium text-poppins">
              <li>Manage your loan online</li>
              <li>See your next payment date</li>
              <li>See how much points you've earned</li>
              <li>Make payments with your points</li>
            </ul>
          </div>
          <div class="mt-10">
            <a href="/auth/register" class="rounded-full bg-rfs-orange-ff9933 text-white px-4 py-2 uppercase">Register</a>
            <span class="ml-2"></span>&nbsp;&nbsp;
            <a href="/auth/login" class="rounded-full bg-rfs-green-009933 text-white px-4 py-2 uppercase">&nbsp;&nbsp;&nbsp;Log In&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>

        </div>


        <div class="w-full text-center p-20">
          <img src="/assets/images/learn-more-asset.png" />
        </div>

    </Fragment>
  );
}
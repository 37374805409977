import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import AlreadyConnected from "../components/AlreadyConnected";

export default function Connect() {

    return (
      <>
        <Header title="Connect My Loan Accounts" />
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 p-10">
          {!localStorage.is_registered && <><div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">If you've applied for a loan at Regions Financial Services, you will be able to connect it here.</p>
              </div>
            </div>
          </div></>}
          
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <div className="">
                <div className="rounded-md bg-blue-50 p-25 bg-white" style={{padding: "25px"}}>

                  {localStorage.is_registered && <>
                    <AlreadyConnected />
                  </>
                  }

                  {!localStorage.is_registered && <>

                    <p>
                      Let's take you through a simple verification wizard to get access to your loans.
                    </p>
                    <br/>
                    <h3><strong>Please choose an option below.</strong></h3>
                    <br/>
                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                      <li className="py-5">
                        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                          <h3 className="text-sm font-semibold text-gray-800">
                            <a href="/app/connect/bio" className="hover:underline focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true"/>
                              Search with personal info
                            </a>
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">Use your names, TRN and date of birth
                            to find your loan accounts.</p>
                        </div>
                      </li>
                      <li className="py-5">
                        <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                          <h3 className="text-sm font-semibold text-gray-800">
                            <a href="/app/connect/accounts" className="hover:underline focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true"/>
                              Search with loan account number
                            </a>
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 line-clamp-2">Use your client or loan number to
                            connect your loan accounts.</p>
                        </div>
                      </li>
                    </ul>

                  </>
                  }
                </div>
              </div>
            </div>
          </main>

          <br /><br />
          
        </div>
      </>
    );
  }
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Connect from "./pages/Connect";
import ConnectUsingAccounts from "./pages/ConnectUsingAccounts";
import ConnectUsingBio from "./pages/ConnectUsingBio";

export default function () {
    return (
      <main>
        <Routes>
          <Route path="/" element={<Connect />} />
          <Route path="/start" element={<Connect />} />
          <Route path="/bio" element={<ConnectUsingBio />} />
          <Route path="/accounts" element={<ConnectUsingAccounts />} />
        </Routes>
      </main>
    );
  }
import Header from "../../../Components/Header";
import {useEffect, useState} from "react";
import { HomeIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid'
import {Bars3Icon, BellIcon, PhoneIcon, XMarkIcon} from '@heroicons/react/24/outline'
import { Disclosure, Menu, RadioGroup, Transition } from '@headlessui/react'
import {Input} from "../../../Utils/Template";
import Http from "../../../Utils/Http";
import Notifier from "../../../Utils/Notifier";

const user = {
    name: 'Floyd Miles',
    email: 'floy.dmiles@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Dashboard', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Applicants', href: '#' },
    { name: 'Company', href: '#' },
]
const breadcrumbs = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true },
]
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]
const team = [
    {
        name: 'Calvin Hawkins',
        email: 'calvin.hawkins@example.com',
        imageUrl:
            'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Bessie Richards',
        email: 'bessie.richards@example.com',
        imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Floyd Black',
        email: 'floyd.black@example.com',
        imageUrl:
            'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]
const settings = [
    { name: 'Public access', description: 'This project would be available to anyone who has the link' },
    { name: 'Private to Project Members', description: 'Only members of this project would be able to access' },
    { name: 'Private to you', description: 'You are the only one able to access this project' },
]

export default function Profile() {

    const [profile, setProfile] = useState({});

    const handleChange = e =>{
        setProfile({...profile, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = async () => {
        let body = {};
        const response = await Http.request({uri: `/user/get-profile`, body});
        if (response.notification) {
            Notifier(response.notification);
        }
        setProfile(response.body.profile);
    }

    const submitProfileUpdate = async () => {
        let body = {...profile};
        const response = await Http.request({uri: '/user/update-profile', body});
        if (response.notification) {
            Notifier(response.notification);
        }
        if ( response.body.emailChanged === true ) {
            let sendCodeResponse = await Http.request({uri: '/auth/register/send-code', body: {email: body.email}});
            if (sendCodeResponse.notification) {
                Notifier(sendCodeResponse.notification);
            }
        }
    }

    return (
      <>
        <Header title={"My Profile"} />
          <div>
              <main className="mx-auto max-w-lg px-4 pt-10 pb-12 lg:pb-16">
                  <div className="mt-8">
                      <h1><strong>Account Info</strong></h1>
                      <div className="mt-6">
                          <div className="space-y-6">
                              <div>
                                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                      Email addresses
                                  </label>
                                  <p className="font-small text-gray-600 text-sm">
                                      <small>If you change your email address, you will need to confirm it before you can use it to log in again.</small>
                                  </p>
                                  <div className="mt-1">
                                      <Input
                                          id="email"
                                          name="email"
                                          type="email"
                                          value={profile.email}
                                          required={true}
                                          autoComplete="email"
                                          onChange={e => handleChange(e)}
                                      />
                                  </div>
                                  <br />
                                  <div className="mt-1">
                                      <label className="block text-sm font-medium text-gray-700">Email addresses on customer file:</label>
                                      {profile.emailAddresses &&
                                          <>
                                              {profile.emailAddresses.map(item => <p>&middot; {' '} {item}</p>)}
                                          </>
                                      }
                                  </div>
                              </div>

                              <div>
                                  <label className="block text-sm font-medium text-gray-700">
                                      Phone Numbers
                                  </label>
                                  <div className="mt-1">
                                      {profile.phoneNumbers &&
                                      <>
                                          {profile.phoneNumbers.map(item => <p>&middot; {' '} {item}</p>)}
                                      </>
                                      }
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
                  <div className="mt-8">
                      <h1><strong>Password</strong></h1>
                      <div className="mt-6">
                          <div className="space-y-6">
                              <div className="space-y-1">
                                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                      Current Password
                                  </label>
                                  <p className="font-small text-gray-600 text-sm">
                                      <small>Current password is required in order to facilitate a change.</small>
                                  </p>
                                  <div className="mt-1">
                                      <Input
                                          id="currentPassword"
                                          name="password"
                                          type="password"
                                          value={profile.currentPassword}
                                          onChange={e => handleChange(e)}
                                      />
                                  </div>
                              </div>

                              <div className="space-y-1">
                                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                      Password
                                  </label>
                                  <div className="mt-1">
                                      <Input
                                          id="password"
                                          name="password"
                                          type="password"
                                          value={profile.password}
                                          onChange={e => handleChange(e)}
                                      />
                                  </div>
                              </div>

                              <div className="space-y-1">
                                  <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700">
                                      Password (repeat to confirm)
                                  </label>
                                  <div className="mt-1">
                                      <Input
                                          id="passwordConfirm"
                                          name="passwordConfirm"
                                          value={profile.passwordConfirm}
                                          type="password"
                                          onChange={e => handleChange(e)}
                                      />
                                  </div>
                              </div>

                              <div>
                                  <button
                                      type="submit"
                                      className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                      onClick={() => submitProfileUpdate()}
                                  >
                                      Save Changes
                                  </button>
                              </div>

                          </div>
                      </div>
                  </div>
              </main>
          </div>
      </>
    );
  }